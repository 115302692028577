import revive_payload_client_8Kw7eqtVWd from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ty_ndjng63srerbsyeqhbmq345nkm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0L8IBUY0J5 from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ty_ndjng63srerbsyeqhbmq345nkm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zYzTLhZby5 from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ty_ndjng63srerbsyeqhbmq345nkm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_04c5K5Bs7h from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.3.1_vue@3.4.29/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_UtgofFV592 from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ty_ndjng63srerbsyeqhbmq345nkm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_wjvDhv1LxF from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ty_ndjng63srerbsyeqhbmq345nkm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/var/builds/t3headlessfront/production/releases/135956/t3h-front/.nuxt/components.plugin.mjs";
import prefetch_client_sxIm6G4Zkc from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ty_ndjng63srerbsyeqhbmq345nkm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/var/builds/t3headlessfront/production/releases/135956/t3h-front/.nuxt/formkitPlugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/var/builds/t3headlessfront/production/releases/135956/t3h-front/.nuxt/pwa-icons-plugin.ts";
import pwa_client_uNR2FInwkE from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_rollup@2.79.1_vite@5.3.1_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_zHgv4rdq88 from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt-delay-hydration@1.3.4_rollup@2.79.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_imDM2Re35q from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.29/node_modules/@t3headless/nuxt-typo3/dist/runtime/plugin.mjs";
import chunk_reload_client_lj1oaj9yF8 from "/var/builds/t3headlessfront/production/releases/135956/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ty_ndjng63srerbsyeqhbmq345nkm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import click_outside_h7lGnWxOxp from "/var/builds/t3headlessfront/production/releases/135956/macopedia-ui/plugins/click-outside.ts";
import gtm_client_pGaoj33AfN from "/var/builds/t3headlessfront/production/releases/135956/macopedia-shared/plugins/gtm.client.ts";
import sentry_client_zg0f4Ya8kn from "/var/builds/t3headlessfront/production/releases/135956/macopedia-shared/plugins/sentry.client.ts";
export default [
  revive_payload_client_8Kw7eqtVWd,
  unhead_0L8IBUY0J5,
  router_zYzTLhZby5,
  _0_siteConfig_04c5K5Bs7h,
  payload_client_UtgofFV592,
  check_outdated_build_client_wjvDhv1LxF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_sxIm6G4Zkc,
  formkitPlugin_pZqjah0RUG,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_uNR2FInwkE,
  nuxt_plugin_zHgv4rdq88,
  plugin_imDM2Re35q,
  chunk_reload_client_lj1oaj9yF8,
  click_outside_h7lGnWxOxp,
  gtm_client_pGaoj33AfN,
  sentry_client_zg0f4Ya8kn
]